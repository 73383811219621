
import { GET_CLIENTS, GET_IMPORT_STATISTICS, SEARCH_PRODUCTS } from '@/graphlql/queries';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DashboardCard from '../DashboardCard.vue';

@Component({
  components: {
    DashboardCard,
  },
  apollo: {
    clients: {
      query: GET_CLIENTS,
    },
    searchProducts: {
      query: SEARCH_PRODUCTS,
      variables: {
        searchProductsPage: 0,
        searchProductsSize: 21,
        searchProductsQuery: '',
        searchProductsArgs: [],
        searchProductClient: null,
        searchProductsOrder: 'pop',
      },
      result(data) {
        if (data != null && data.loading == false) {
          this.searchLoading = false;
          this.searchLastUpdate = new Date().toLocaleString();
        }
      },
    },
    searchProducts2: {
      query: SEARCH_PRODUCTS,
      manual: true,
      variables: {
        searchProductsPage: 0,
        searchProductsSize: 21,
        searchProductsQuery: '',
        searchProductsArgs: [],
        searchProductClient: 2,
        searchProductsOrder: 'pop',
      },
      result(data) {
        if (data != null && data.loading == false) {
          this.search2Loading = false;
          this.search2Products = data.data.searchProducts;

          this.search2LastUpdate = new Date().toLocaleString();
        }
      },
    },
    importStatistics: {
      query: GET_IMPORT_STATISTICS,
      manual: true,

      result(data) {
        if (data != null && data.loading == false) {
          if (data.data.getImportStatistics != null) {
            this.importStatistics = data.data.getImportStatistics;
            this.importStatisticsLastUpdate = new Date().toLocaleString();
          }
        }
      },
    },
  },
})
export default class SearchEngineStatistics extends Vue {
  @Prop({ required: false })
  content?: any;
  @Prop({ required: false })
  element?: any;

  searchLoading = true;
  searchLastUpdate: string = '';
  searchProducts: any = null;

  search2Loading = true;
  search2LastUpdate: string = '';
  search2Products: any = null;

  importStatistics: any = null;
  importStatisticsLastUpdate: any = null;
}
